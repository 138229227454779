import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "~/components/ui/toast";

import { useToast } from "~/hooks/use-toast";

import { CheckSuccessIcon, ErrorIcon, InfoIcon, WarningIcon } from "./icons";

const renderIcon = (variant: string) => {
  if (variant === "success") return <CheckSuccessIcon />;
  if (variant === "error") return <ErrorIcon />;
  if (variant === "warning") return <WarningIcon />;
  if (variant === "info") return <InfoIcon />;
  return null;
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        variant = "info",
        ...props
      }) {
        return (
          <Toast
            key={id}
            {...props}
            variant={
              variant as
                | "success"
                | "error"
                | "warning"
                | "info"
                | null
                | undefined
            }
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                {renderIcon(variant)}

                {title && (
                  <ToastTitle className="font-semibold text-lg">
                    {title}
                  </ToastTitle>
                )}
              </div>
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
