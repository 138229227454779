"use client";

import {
  Settings,
  User,
  Search,
  Building2,
  BookMarked,
  Bell,
  MessageSquare,
} from "lucide-react";
import * as React from "react";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "~/components/ui/command";

export function CommandPalette() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput placeholder="コマンドを入力または検索..." />
      <CommandList>
        <CommandEmpty>結果が見つかりませんでした。</CommandEmpty>
        <CommandGroup heading="クイックアクセス">
          <CommandItem>
            <Search className="mr-2 h-4 w-4" />
            <span>企業を検索</span>
            <CommandShortcut>⌘F</CommandShortcut>
          </CommandItem>
          <CommandItem>
            <Building2 className="mr-2 h-4 w-4" />
            <span>おすすめ企業</span>
          </CommandItem>
          <CommandItem>
            <BookMarked className="mr-2 h-4 w-4" />
            <span>保存した企業</span>
          </CommandItem>
        </CommandGroup>
        <CommandSeparator />
        <CommandGroup heading="通知">
          <CommandItem>
            <Bell className="mr-2 h-4 w-4" />
            <span>通知一覧</span>
            <CommandShortcut>⌘N</CommandShortcut>
          </CommandItem>
          <CommandItem>
            <MessageSquare className="mr-2 h-4 w-4" />
            <span>メッセージ</span>
            <CommandShortcut>⌘M</CommandShortcut>
          </CommandItem>
        </CommandGroup>
        <CommandSeparator />
        <CommandGroup heading="設定">
          <CommandItem>
            <User className="mr-2 h-4 w-4" />
            <span>プロフィール</span>
            <CommandShortcut>⌘P</CommandShortcut>
          </CommandItem>
          <CommandItem>
            <Settings className="mr-2 h-4 w-4" />
            <span>設定</span>
            <CommandShortcut>⌘S</CommandShortcut>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}
