import { useLocation, useSubmit } from "@remix-run/react";
import { Bell } from "lucide-react";
import { useState, useRef, useEffect } from "react";

import { Logo, Link } from "~/components";
import { useUser } from "~/context/user";

function CustomDropdown({
  trigger,
  children,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => e.key === "Enter" && setIsOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        {trigger}
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-[200px] bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 top-full">
          {children}
        </div>
      )}
    </div>
  );
}

export function BizHeader() {
  const location = useLocation();
  const { user } = useUser();

  const submit = useSubmit();

  const handleLogout = async () => {
    try {
      const formData = new FormData();
      submit(formData, {
        method: "POST",
        action: "/logout",
      });
    } catch (error) {
      console.error("ログアウト処理に失敗しました:", error);
    }
  };

  const links = [
    { to: "/biz/status", text: "選考状況" },
    { to: "/biz/department", text: "事業部情報" },
    { to: "/biz/about", text: "企業情報" },
    { to: "/biz/candidate/management", text: "候補者管理" },
    { to: "/biz/candidate/list", text: "候補者検索" },
  ];

  return (
    <header className="border-b border-gray bg-bg-light">
      <div className="w-full max-w-screen-xl mx-auto px-4 h-16 flex items-center justify-between">
        <Logo />
        <nav className="flex items-center gap-6">
          {links.map((link) => (
            <Link
              key={link.to}
              href={link.to}
              className={`text-sm ${
                location.pathname === link.to
                  ? "text-primary border-b-2 border-primary font-semibold"
                  : "text-gray-500"
              }`}
            >
              {link.text}
            </Link>
          ))}
        </nav>
        <div className="flex items-center gap-4">
          <Link to="/home" className="text-sm text-gray-500">
            ユーザーページを見る
          </Link>
          <span className="text-gray-300">|</span>
          <CustomDropdown
            trigger={<Bell className="h-5 w-5 text-gray-500 cursor-pointer" />}
          >
            <div className="py-1">
              <div className="px-4 py-2 text-sm font-medium">通知</div>
              <div className="border-t border-gray-100" />
              <div className="px-4 py-2 text-sm">
                通知内容がここに表示されます。
              </div>
            </div>
          </CustomDropdown>
          <CustomDropdown
            trigger={
              <span className="text-sm text-gray-500 cursor-pointer">
                {user?.company?.name}
              </span>
            }
          >
            <div className="space-y-2 p-2">
              <Link
                href="/biz/member"
                className="px-4 py-4 text-sm font-medium"
              >
                メンバー設定
              </Link>
              <div className="border-t border-gray-100" />
              <button onClick={handleLogout} className="px-4 py-2 text-sm">
                ログアウト
              </button>
            </div>
          </CustomDropdown>
        </div>
      </div>
    </header>
  );
}
