import { Form, useLocation } from "@remix-run/react";
import {
  User,
  Settings,
  LogOut,
  Bell,
  Bookmark,
  MessageSquare,
} from "lucide-react";
import { useState, useRef, useEffect } from "react";

import { CommandPalette } from "~/components/command-palette";
import { Button } from "~/components/ui/button";
import { Logo } from "~/components/ui/logo";

import { useUser } from "~/context/user";
import { useSearch } from "~/hooks/use-search";

import { Link } from "./ui";

// TODO 既読
function CustomDropdown({
  trigger,
  children,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => e.key === "Enter" && setIsOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        {trigger}
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-[600px] bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 top-full">
          {children}
        </div>
      )}
    </div>
  );
}

export function Header({ notifications }: { notifications: Notification[] }) {
  const { user } = useUser();

  const {
    filters,
    handleFilterChange,
    handleImmediateSearch,
    debouncedSearch,
  } = useSearch({
    onSearch: (query) => {
      // 検索パラメータでURLを更新
      const url = new URL(window.location.href);
      url.search = query;
      window.history.pushState({}, "", url.toString());
    },
  });

  const location = useLocation();

  return (
    <header className="border-b border-gray bg-bg-light">
      <CommandPalette />
      <div className="w-full max-w-screen-xl mx-auto px-4 h-16 flex items-center justify-between">
        <Logo />
        <div className="flex items-center gap-4">
          {/* <Input
            type="text"
            placeholder="企業を検索する"
            className="w-[300px]"
            value={filters.keyword}
            onChange={(e) => handleFilterChange({ keyword: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleImmediateSearch(filters);
                debouncedSearch.cancel();
              }
            }}
          /> */}

          <Link
            href="/companies/saved"
            className={`inline-block text-lg px-4 py-2 text-md hover:font-semibold hover:border-b-[3px] hover:border-primary no-underline ${
              location.pathname === "/companies/saved"
                ? "text-black border-b-[3px] border-primary font-semibold"
                : "text-gray-500 hover:bg-gray-50"
            }`}
          >
            保存した企業
          </Link>
          <Link
            href="/companies/entries"
            className={`inline-block text-lg px-4 py-2 text-md hover:font-semibold hover:border-b-[3px] hover:border-primary no-underline ${
              location.pathname === "/companies/entries"
                ? "text-black border-b-[3px] border-primary font-semibold"
                : "text-gray-500 hover:bg-gray-50"
            }`}
          >
            エントリー企業
          </Link>

          {user ? (
            <>
              <CustomDropdown
                trigger={
                  <Link href="/companies/saved">
                    <Button variant="ghost" size="icon">
                      <Bookmark className="h-5 w-5 text-gray-500" />
                    </Button>
                  </Link>
                }
              >
                <div className="py-1"></div>
              </CustomDropdown>
              <CustomDropdown
                trigger={
                  <Link href="/message">
                    <Button variant="ghost" size="icon">
                      <MessageSquare className="h-5 w-5 text-gray-500" />
                    </Button>
                  </Link>
                }
              >
                <div className="py-1"></div>
              </CustomDropdown>
              <CustomDropdown
                trigger={
                  <Button variant="ghost" size="icon">
                    <Bell className="h-5 w-5 text-gray-500" />
                  </Button>
                }
              >
                <div className="py-2">
                  <div className="px-4 py-2 text-lg font-bold">通知一覧</div>
                  <div className="border-t border-gray-100" />
                  {/* 通知アイテムのリスト */}
                  {notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="flex items-start p-4 border-b border-gray-100"
                    >
                      <div className="flex-1">
                        <div className="text-sm font-semibold">
                          {notification.title}
                        </div>
                        <div className="text-sm text-gray-500">
                          {notification.message}
                        </div>
                        <div className="text-xs text-gray-400 mt-1">
                          {notification.date}
                        </div>
                      </div>
                      {notification.isNew && (
                        <span className="text-red-500 ml-2">●</span>
                      )}
                    </div>
                  ))}
                </div>
              </CustomDropdown>
              <CustomDropdown
                trigger={
                  <Button variant="ghost" className="flex items-center gap-2">
                    <span className="text-sm">
                      {user.firstName} {user.lastName}
                    </span>
                  </Button>
                }
              >
                <div className="py-1">
                  <div className="px-4 py-2 text-sm font-medium">
                    アカウント
                  </div>
                  <div className="border-t border-gray-100" />
                  <Link href="/profile" className="hover:text-natural-gray-900">
                    <button className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 flex items-center">
                      <User className="mr-2 h-4 w-4" />
                      <span>プロフィール</span>
                    </button>
                  </Link>
                  {/* <Link
                    href="/settings/sns"
                    className="hover:text-natural-gray-900"
                  >
                    <button className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 flex items-center">
                      <Settings className="mr-2 h-4 w-4" />
                      <span>設定</span>
                    </button>
                  </Link> */}
                  <div className="border-t border-gray-100" />
                  <Form method="post" action="/user/logout">
                    <button className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 flex items-center text-red-600">
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>ログアウト</span>
                    </button>
                  </Form>
                </div>
              </CustomDropdown>
            </>
          ) : (
            <Button variant="primary">
              <Link href="/login" className="text-white">
                ログイン
              </Link>
            </Button>
          )}
        </div>
      </div>
    </header>
  );
}
