import {
  LinksFunction,
  LoaderFunction,
  LoaderFunctionArgs,
  json,
} from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
  useLoaderData,
  useFetcher,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { useEffect } from "react";

import { getUserFromSession } from "~/auth.supabase.server";
import stylesheet from "~/tailwind.css?url";

import { BizHeader, Footer, Header } from "./components";
import { Toaster } from "./components/ui/toaster";
import { UserProvider } from "./context/user";
import { useToast } from "./hooks/use-toast";
import { NotificationService } from "./services/notification.service";
import { createSupabaseServerClient } from "./supabase.server";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },

  // Basic favicon
  {
    rel: "icon",
    href: "/favicon/favicon.ico",
  },
  {
    rel: "icon",
    type: "image/svg+xml",
    href: "/favicon/favicon.svg",
  },

  // Apple Touch Icon
  {
    rel: "apple-touch-icon",
    href: "/favicon/apple-touch-icon.png",
  },

  // PWA icons
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: "/favicon/favicon-96x96.png",
  },

  // Web Manifest
  {
    rel: "manifest",
    href: "/favicon/site.webmanifest",
  },
];

export const loader: LoaderFunction = async ({
  request,
}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const path = url.pathname;

  const supabase = createSupabaseServerClient(request);
  const session = await supabase.client.auth.getSession();
  const user = await getUserFromSession(supabase.client, session);

  let notifications = [];
  if (user) {
    const notificationService = new NotificationService();
    notifications = await notificationService.getUserNotifications(user.id);
  }

  return json({ authorized: true, user, session, notifications });
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.error("Caught route error:", {
    message: error instanceof Error ? error.message : "Unknown error",
    // stack: error instanceof Error ? error.stack : "No stack trace",
    // type: typeof error,
    // stringified: JSON.stringify(error),
  });
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <h1 className="text-4xl font-bold text-gray-900">
          {error.status} {error.statusText}
        </h1>
        <p className="mt-2 text-gray-600">{error.data}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <h1 className="text-4xl font-bold text-gray-900">エラーが発生しました</h1>
    </div>
  );
};

export default function App() {
  const { user, notifications } = useLoaderData<typeof loader>();
  const fetcher = useFetcher({ key: "toast" });

  const { toast } = useToast();
  const isShowBizHeader = user?.role === "COMPANY_USER";

  useEffect(() => {
    // fetcher.stateがidleの時のみトーストを表示
    if (fetcher.state === "idle" && fetcher.data?.toast) {
      toast({
        title: fetcher.data.toast.title,
        description: fetcher.data.toast.message,
        variant: fetcher.data.toast.status === 200 ? "success" : "error",
      });
    }
  }, [fetcher.state, fetcher.data, toast]);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <UserProvider user={user}>
          {isShowBizHeader ? (
            <BizHeader />
          ) : (
            <Header notifications={notifications} />
          )}
          <div className="min-h-full flex flex-col bg-bg-light">
            <Outlet />
            <Toaster />
          </div>
          <Footer />
        </UserProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}
