import { Link } from "@remix-run/react";

import { Logo } from "~/components/ui/logo";

const FOOTER_LINKS = [
  { href: "/guide", label: "ご利用ガイド" },
  { href: "/faq", label: "よくある質問" },
  { href: "/privacy", label: "プライバシーポリシー" },
  { href: "/sitemap", label: "サイトマップ" },
] as const;

export function Footer() {
  return (
    <footer className="border-t bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center gap-6">
          {/* ナビゲーションリンク */}
          <nav>
            <ul className="flex gap-6">
              {FOOTER_LINKS.map((link) => (
                <li key={link.href}>
                  <Link
                    to={link.href}
                    className="text-sm text-primary hover:text-brand-400 transition-colors"
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* ロゴ */}
          <Logo size="md" className="text-brand" />

          {/* コピーライト */}
          <p className="text-sm text-primary">
            Copyright © BlueAI.inc ALL right reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
